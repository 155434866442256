<template>
  <div class="footer-bar flex flex-vc">
    <!-- 底部导航信息展示 -->
    <div class="container flex flex-jsb">
      <div class="left flex flex-v">
        <el-button type="primary" class="onlineService" @click="goService">{{
          $t("innerLayout.footer.onlineService")
        }}</el-button>
        <div class="contact flex flex-vc">
          <div class="contact-item flex flex-vc">
            <img src="@/assets/inner-layout/phone.png" alt="" />
            <span>+852-21878188</span>
          </div>
          <div class="contact-item flex flex-vc">
            <img src="@/assets/inner-layout/email.png" alt="" />
            <span>enquiry@qkintl.com</span>
          </div>
          <div class="contact-item flex flex-vc">
            <img src="@/assets/inner-layout/pager.png" alt="" />
            <span>+852-21290216</span>
          </div>
        </div>
        <div class="location flex flex-vc">
          <img src="@/assets/inner-layout/location.png" alt="" />
          <span>{{ $t("innerLayout.footer.address") }}</span>
        </div>
        <div class="location flex flex-vc">
          <img src="@/assets/inner-layout/time.png" alt="" />
          <span>{{ $t("contact.workTime") }}</span>
        </div>

        <div class="qkInfo flex flex-v">
          <div class="flex flex-vc police">
            <span v-html="$t('innerLayout.footer.qkInfoTop')"></span>
            <!-- <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047968"
              target="_blank"
              class="recordInfo"
            >
              <div class="flex flex-vc">
                <img src="@/assets/guohui.jpg" alt="" /><span>{{
                  $t("innerLayout.footer.recordInfo")
                }}</span>
              </div>
            </a> -->
          </div>
          <span v-html="$t('innerLayout.footer.qkInfoBottom')"> </span>
        </div>
      </div>
      <div class="right flex flex-v">
        <div
          class="plate flex flex-vc"
          v-html="$t('innerLayout.footer.plate1')"
        ></div>
        <div
          class="plate flex flex-vc"
          v-html="$t('innerLayout.footer.plate2')"
        ></div>
        <div
          class="plate flex flex-vc"
          v-html="$t('innerLayout.footer.plate3')"
        ></div>
        <div class="aboutList flex flex-vc">
          <a href="javascript:;" @click="$router.push('/disclaimer')">{{
            $t("innerLayout.footer.disclaimer")
          }}</a>
          <a href="javascript:;" @click="$router.push('/privacy-policy')">{{
            $t("innerLayout.footer.privacy")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {
    goService() {
      _MEIQIA("showPanel");
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-bar {
  // height: 267px;
  padding: 60px 0;
  background-color: var(--footerBgColor);
  .container {
    color: var(--whiteColor);
    font-size: 14px;
  }
  .left {
    .contact {
      margin-top: 24px;
      .contact-item {
        margin-right: 30px;
        img {
          width: 18px;
          margin-right: 4px;
        }
        &:last-child {
          margin-right: 0;
          img {
            width: 14px;
          }
        }
      }
    }
    .location {
      margin-top: 12px;
      img {
        width: 18px;
        margin-right: 4px;
      }
    }
    .onlineService {
      background-color: var(--blue);
      width: 240px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0px 8px 16px 0px rgba(54, 95, 163, 0.4);
      border: none;
      &:active {
        background: #3a8ee6;
        border-color: #3a8ee6;
        color: #fff;
      }
    }
    .qkInfo {
      margin-top: 24px;
      font-size: 12px;
      line-height: 17px;
      .recordInfo {
        // width: 220px;
        color: var(--whiteColor);
        img {
          width: 15px;
          margin-right: 3px;
        }
      }
      .police {
        a {
          margin-left: 10px;
        }
      }
    }
  }
  .right {
    margin: 9px 90px 0 0;
    .aboutList {
      a {
        text-decoration: underline;
        color: var(--blue);
        margin-right: 36px;
      }
    }

    .plate {
      font-size: 12px;
      margin: 0px 0 24px;
      line-height: 18px;
    }
  }
}
</style>
