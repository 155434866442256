<template>
  <el-dialog
    title=""
    :visible.sync="dialogVisible"
    width="400px"
    :show-close="false"
    append-to-body
    lock-scroll
  >
    <div class="tipBox">
      <img
        class="close-icon"
        src="../../assets/common/close_icon.png"
        alt=""
        @click="dialogVisible = false"
      />
      <div class="title text-center">{{ $t("common.personTitle") }}</div>
      <div class="content text-center">
        <div class="flex flex-hc app-down">
          <!-- <div class="ios-app-code app-code flex flex-v flex-vc">
            <img
              class="empty-img"
              src="@/assets/download/ios_code.png"
              alt=""
            />
            <span>iOS版</span>
          </div>
          <div class="app-code flex flex-v flex-vc">
            <img
              class="empty-img"
              src="@/assets/download/android_code.png"
              alt=""
            />
            <span>Android版</span>
          </div> -->

          <img class="empty-img" src="@/assets/download/ios_code.png" alt="" />
        </div>
        <div class="desc">
          {{ $t("common.personDesc")
          }}<span class="clickWatch" @click="goPage('person')">{{
            $t("common.clickWatch")
          }}</span>
        </div>
      </div>
      <div class="h-line"></div>
      <div class="title text-center">{{ $t("common.mechanismTitle") }}</div>
      <div class="content text-center">
        <div class="desc">
          {{ $t("common.mechanismDesc") }}
          <span class="clickWatch" @click="goPage('mechanism')">{{
            $t("common.clickWatch")
          }}</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.value
    };
  },
  computed: {
    personOpenHelpSrc() {
      const lang = this.$i18n.locale;
      let obj;
      if (lang === "zh") obj = { name: "个人如何开户", cate_name: "开户相关" };
      else obj = { name: "個人如何開戶", cate_name: "開戶相關" };
      const itemObj = {
        article_id: 2,
        ...obj
      };
      const path = {
        path: "/helpDetail",
        query: {
          info: JSON.stringify(itemObj)
        }
      };
      return path;
    },
    mechanismOpenHelpSrc() {
      const lang = this.$i18n.locale;
      let obj;
      if (lang === "zh")
        obj = { name: "机构如何开户？", cate_name: "开户相关" };
      else obj = { name: "機構如何開戶？", cate_name: "開戶相關" };
      const itemObj = {
        article_id: 1,
        ...obj
      };
      const path = {
        path: "/helpDetail",
        query: {
          info: JSON.stringify(itemObj)
        }
      };
      return path;
    }
  },
  watch: {
    value(n, o) {
      if (n === o) return;
      this.dialogVisible = n;
    },
    dialogVisible(n, o) {
      if (n === o) return;
      this.$emit("input", n);
    }
  },
  methods: {
    goPage(type) {
      if (type === "person") this.$router.push(this.personOpenHelpSrc);
      else this.$router.push(this.mechanismOpenHelpSrc);
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  position: relative;
  .el-dialog__header {
    height: 0;
    padding: 0;
  }
  .el-dialog__body {
    padding: 36px 25px;
  }
  .el-dialog__footer {
    padding: 0;
    height: 0;
  }
}
.tipBox {
  .title {
    color: var(--blackColor);
    font-size: 18px;
    font-weight: 600;
  }
  .content {
    margin-top: 12px;
    color: var(--blackColor);
    font-size: 14px;
    .desc {
      //   margin-top: 16px;
    }
    .app-down {
      margin-bottom: 16px;
      margin-top: 10px;
      font-size: 18px;
      .app-code {
        img {
          margin-bottom: 5px;
        }
      }
      .ios-app-code {
        margin-right: 15px;
      }
    }
  }
  .empty-img {
    width: 150px;
    margin: 4px 0 0px;
  }
  .clickWatch {
    color: var(--blue);
    cursor: pointer;
  }
  .h-line {
    width: 100%;
    height: 1px;
    background: var(--borderColor);
    margin: 36px 0;
  }
  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    cursor: pointer;
  }
}
</style>
